import React, { FC } from 'react'
// import { toast } from 'react-toastify'
import { DepositBank } from '../../@types/prefix'
import { getBankColor, getBankName, Banks } from '../../helpers/bank'
import { Button, Typography, message } from 'antd'
import { CopyOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

const { Text } = Typography

interface BankDepositItemProps {
	bank: DepositBank
}
const BankDepositItem: FC<BankDepositItemProps> = ({ bank }) => {
	const [messageApi, contextHolder] = message.useMessage();
	const prefix = useSelector((state: ApplicationState) => state.prefix.info)
	const copyAccountNumber = () => {
		navigator.clipboard.writeText(bank.accountNumber)
		messageApi.open({
			type: 'success',
			content: 'คัดลอกเลขที่บัญชีสำเร็จ',
		});
	}

	const replaceText = (text: string): string => {
		return text.split(' ').map((word, index) => {
			if (index > 0 && word.length > 2) {
				return word.substring(0, 1) + '*'.repeat(word.length - 1);
			}
			return word;
		}).join(' ');
	}

	return (
		<>
			{contextHolder}
			<div className="bank-deposit-card bank-to" style={{ marginBottom: 16 }}>
				<div
					className="bank-card-logo"
					style={{ boxShadow: `0 8px 12px -4px ${getBankColor(bank.bank)}` }}
				>
					<img
						src={Banks.filter(bankName => bankName.key === bank?.bank.toUpperCase())[0].image}
						alt={getBankName(bank.bank)}
					/>
				</div>
				<div className="bank-card-info">
					<div><Text type="secondary"><small>{getBankName(bank.bank)}</small></Text></div>
					<Text className="bank-deposite-account">{bank.accountNumber}</Text>
					<div><Text type="secondary"><small>{(prefix?.prefix === "AMBGAME" || prefix?.prefix === "ALD88" || prefix?.prefix === "WK89") ? replaceText(bank.accountName) : bank.accountName}</small></Text></div>
				</div>
				<Button
					type="primary"
					onClick={copyAccountNumber}
					style={{ display: 'block', height: 'auto', marginLeft: 'auto' }}
				>
					<CopyOutlined />
					<div><small>คัดลอก</small></div>
				</Button>
			</div>
		</>
	)
}

export default BankDepositItem
