import React, { FC } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Member } from '../../@types/member'
import { Prefix } from '../../@types/prefix'
import { topbarText } from '../../constant'
import { makePath } from '../../helpers/path'
import { Avatar, Button, Typography } from 'antd';

const { Title } = Typography;

interface TopbarProps {
	prefix?: Prefix
	member?: Member

	pathname: string
}
const Topbar: FC<TopbarProps> = ({ prefix, member, pathname }) => {
	const loc = useLocation()
	const history = useHistory()

	return (
		<>
			<div className={`topbar topbar-home ${pathname === '/home' ? '' : 'topbar-hidden'} ${prefix?.prefix === 'VR88' ? 'topbar-dark' : ''}`}>
				{pathname === '/home' && (
					<div className="topbar-logo">
						<Link to={makePath('/home')}>
							{prefix?.logo ? (
								<Avatar
									className="avatar-logo avatar-logo-topbar"
									src={prefix?.logo}
									shape="square"
								/>
							) : (
								<div className="prefix-name">{prefix?.prefix}</div>
							)}
						</Link>
					</div>
				)}

				<div className="topbar-welcome">
					<Title level={4} style={{ margin: 0 }}>{topbarText[loc.pathname] ?? `หน้าหลัก`}</Title>
				</div>

				<div className="topbar-member">
					<Button
						className="ant-btn-secondary"
						style={{ display: 'block' }}
						onClick={() => history.push(makePath('/login'))}
					>
						เข้าสู่ระบบ
					</Button>
					{(!prefix?.functions.hasOwnProperty("offRegister") || !prefix?.functions['offRegister']) && (
						<Button
							className={"ant-btn-tertiary"}
							style={{ marginLeft: 12, display: 'block' }}
							onClick={() => history.push(makePath('/register'))}
						>
							สมัครสมาชิก
						</Button>
					)}
				</div>
			</div>
		</>
	)
}

export default Topbar
