// import { useFormik } from 'formik'
import qs from 'querystring'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import RegisterStep1 from '../components/Register/RegisterStep1'
import RegisterStep2 from '../components/Register/RegisterStep2'
import RegisterStep3 from '../components/Register/RegisterStep3'
import RegisterStepVerifyOTP from '../components/Register/RegisterStepVerifyOTP'
import api from '../helpers/api'
import { makePath } from '../helpers/path'
import AuthService from '../services/auth'
import { ApplicationState } from '../store'
import { setUser } from '../store/user/user.actions'
import ModalSupport from '../components/Home/ModalSupport';
import { Button, Card, Form, Typography, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ModalTerms from '../components/Elements/ModalTerms'
import MainSlider from '../components/Wallet/MainSlider'

interface RegisterResponse {
	token: string
}

interface VerifyResponse {
	ref: string
	transaction_id: string
	token: string
	status: boolean
}

const dataRegister = {
	phone: '',
	bankCode: '',
	bankNumber: '',
	firstName: '',
	lastName: '',
	firstNameEN: '',
	lastNameEN: '',
	password: '',
	line: '',
	autoBonus: '',
	knowFrom: '',
	affiliateCode: '',
	captcha: ''
}

const { Title, Text } = Typography;

const Register = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info
	const [showMenuSupportLine, setShowMenuSupportLine] = useState<boolean>(false)
	const [openSupport, setOpenSupport] = useState<boolean>(false)
	const [openTermandconditionModal, setOpenTermandconditionModal] = useState<boolean>(false)
	const handleModalSupport = () => setOpenSupport(!openSupport)
	const termandconditionModal = () => setOpenTermandconditionModal(!openTermandconditionModal)

	const [checkTermandcondition, setCheckTermandcondition] = useState<boolean>(false)
	const [resetCounter, setResetCounter] = useState(0);

	const location = useLocation()
	const history = useHistory();

	const query = qs.parse(location.search.substr(1))
	const refCode = query.ref ? query.ref.toString() : ''


	const params = new URLSearchParams(location.search);
	const getParamsRec = params.get('rec');
	const getParamsRef = params.get('ref');

	if (getParamsRec) {
		localStorage.setItem('rec', getParamsRec)
	}

	if (getParamsRef) {
		localStorage.setItem('ref', getParamsRef)
	}


	let autoBonus = 'off'
	if (refCode && prefix) {
		const promotion = prefix.promotions.find((v) => v.limit === 'once')
		if (promotion) {
			autoBonus = promotion.key
		}
	}

	const [step, setStep] = useState<number>(1)
	const [stepOTP, setStepOTP] = useState<boolean>(false)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [bankCode, setBankCode] = useState<string>('')
	const [verifyRefOTP, setVerifyRefOTP] = useState<string>('')
	const [verifyTransactionID, setVerifyTransactionID] = useState<string>('')
	const [verifyTokenOTP, setVerifyTokenOTP] = useState<string>('')
	const [knowFrom, setKnowFrom] = useState<string>('')
	const [affiliateCode, setAffiliateCode] = useState<string>('')

	const [tokenTurnstileOTP, setTokenTurnstileOTP] = useState<string>('')

	const handleTabs = (key: string) => {
		if (key === "login") {
			history.push(makePath('/login'))
		}
	};

	const itemTabs: TabsProps['items'] = [
		{
			key: 'login',
			label: 'เข้าสู่ระบบ',
		},
		{
			key: 'register',
			label: 'สมัครสมาชิก',
		},
	];

	// const previousPageUrl = document.referrer;
	// const currentDomain = window.location.hostname;

	// const isFromDifferentDomain = previousPageUrl && !previousPageUrl.includes(currentDomain);


	const handleSubmit = async () => {
		if (step === 1) {
			form.validateFields()
				.then(async (values: any) => {
					values.phone = values.phone.replace(/-|_/g, '')
					dataRegister.phone = values.phone
					if (values.phone.length !== 10) {
						toast.error('เบอร์โทรศัพท์ต้องมีความยาว 10 ตัวอักษร')
						return
					}
					if (prefix?.functions.otp_register) {
						if (tokenTurnstileOTP === "") {
							toast.error('กรุณารอ Turnstile โหลดเสร็จสิ้น')
							setResetCounter((prevCounter) => prevCounter + 1);
							return
						}
						values.captcha = tokenTurnstileOTP
					}
					try {
						setIsSubmitting(true)
						const res: VerifyResponse = await api.post('/register/step-1', values)
						if (prefix?.functions.otp_register) {
							if (res.status) {
								setVerifyRefOTP(res.ref)
								setVerifyTransactionID(res.transaction_id)
								setVerifyTokenOTP(res.token)
								setResetCounter((prevCounter) => prevCounter + 1);
								setStepOTP(true) //OTP
							} else {
								toast.error('กรุณารอสักครู่แล้วลองใหม่อีกครั้ง')
								return
							}
						} else {
							setStepOTP(false) //OTP
							setStep(2)
						}
					} catch ({ message }: any) {
						toast.error(message as string)
						setResetCounter((prevCounter) => prevCounter + 1);
					} finally {
						setIsSubmitting(false)
					}
				}).catch((errorInfo) => { });

		} else if (step === 2) {
			form.validateFields()
				.then(async (values: any) => {
					// const thaiRegex = /^[ก-๙ ]+$/g
					// if (!values.firstName.trim().match(thaiRegex)) {
					// 	toast.error('ชื่อต้องเป็นภาษาไทยเท่านั้น')
					// 	return
					// }
					// if (!values.lastName.trim().match(thaiRegex)) {
					// 	toast.error('นามสกุลต้องเป็นภาษาไทยเท่านั้น')
					// 	return
					// }
					if (values.bankCode === '') {
						toast.error('กรุณาเลือกธนาคาร')
						return
					}

					values.bankNumber = values.bankCode === 'TRUEWALLET' ? dataRegister.phone : values.bankNumber

					dataRegister.bankCode = values.bankCode
					dataRegister.bankNumber = values.bankNumber
					dataRegister.firstName = values.firstName
					dataRegister.lastName = values.lastName
					dataRegister.firstNameEN = values.firstNameEN || ""
					dataRegister.lastNameEN = values.lastNameEN || ""

					try {
						setIsSubmitting(true)
						await api.post('/register/step-2', values)

						setStep(3)
					} catch ({ message }: any) {
						toast.error(message as string)
						setResetCounter((prevCounter) => prevCounter + 1);
					} finally {
						setIsSubmitting(false)
					}
				}).catch((errorInfo) => { });
		} else if (step === 3) {
			form.validateFields()
				.then(async (values: any) => {
					if (values.password.length !== 6) {
						toast.error('รหัสผ่านต้องมีความยาว 6 ตัวเลข')
						return
					}

					// const alphaRegex = /(?=.*[a-zA-Z])/g
					// if (!values.password.match(alphaRegex)) {
					//  toast.error('รหัสผ่านต้องมีตัวอักษรอย่างน้อย 1 ตัว')
					//  return
					// }

					const numberRegex = /(?=.*[0-9])/g
					if (!values.password.match(numberRegex)) {
						toast.error('รหัสผ่านต้องเป็นตัวเลขเท่านั้น')
						return
					}

					dataRegister.password = values.password
					dataRegister.line = values.line
					dataRegister.autoBonus = values.autoBonus
					dataRegister.knowFrom = values.knowFrom ? values.knowFrom : knowFrom
					if (values.knowFrom2) {
						dataRegister.knowFrom = dataRegister.knowFrom + ',' + values.knowFrom2
					}
					dataRegister.affiliateCode = values.affiliateCode
					// dataRegister.captcha = values.captcha
					if (window.location.hostname === 'localhost') {
						dataRegister.captcha = values.captcha
					} else {
						dataRegister.captcha = tokenTurnstile
					}
					if (prefix?.termandcondition?.termandconditionDescription) {
						if (!checkTermandcondition) {
							toast.error('กรุณายอมรับเงื่อนไขและข้อตกลงในการใช้บริการ')
							return false
						}
					}

					try {
						setIsSubmitting(true)
						const res = await api.post<void, RegisterResponse>('/register', dataRegister)

						AuthService.setToken(res.token)
						const member = await AuthService.getMemberInfo(true)
						dispatch(setUser(member))

						localStorage.setItem('rec', '')
						localStorage.setItem('ref', '')

						setStep(4)
					} catch ({ message }: any) {
						setIsSubmitting(false)
						setResetCounter((prevCounter) => prevCounter + 1);
						toast.error(message as string)
					}
				}).catch((errorInfo) => { });
		}
		if (stepOTP) {
			form.validateFields()
				.then(async (values: any) => {
					try {
						setIsSubmitting(true)
						const data = {
							phone: dataRegister.phone,
							transaction_id: verifyTransactionID,
							token: verifyTokenOTP,
							otp: values.otp,
							ref: verifyRefOTP
						}
						const res: any = await api.post('/confirm-otp', data)
						setStepOTP(false)
						if (res.status) {
							toast.success("ยืนยัน OTP เสร็จสิ้น")
							setStep(2)
						} else {
							setResetCounter((prevCounter) => prevCounter + 1);
							toast.error("กรุณาลองใหม่อีกครั้ง")
							setStep(1)
						}
						// setStep(2)
					} catch ({ message }: any) {
						toast.error(message as string)
						setResetCounter((prevCounter) => prevCounter + 1);
					} finally {
						setIsSubmitting(false)
					}
				}).catch((errorInfo) => { });
		}
	};
	const [tokenTurnstile, setTokenTurnstile] = useState<string>('')
	const setTurnstile = (token: any) => {
		if (token) {
			// form.setFieldsValue({ "captcha": token })
			setTokenTurnstile(token)
		}
	}

	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMediaLine = prefixState.info?.socialMedia ?
				(Object.keys(prefixState.info?.socialMedia).find((item: any) => item === "line") && prefixState.info?.socialMedia.line !== "" ? true : false)
				: false;
			setShowMenuSupportLine(isNullishSocialMediaLine)


			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	useEffect(() => {
		if (form) {
			const params = new URLSearchParams(location.search);
			const getParamsRec: any = localStorage.getItem('rec')
			const getParamsRef: any = localStorage.getItem('ref')
			if (getParamsRef) {
				params.set('prefix', prefix?.prefix ? prefix?.prefix : '');
				params.set('ref', getParamsRef);
			}
			if (getParamsRec) {
				params.set('rec', getParamsRec);
			}
			history.push({ search: params.toString() });

			dataRegister.autoBonus = autoBonus
			dataRegister.knowFrom = getParamsRef || refCode !== "" ? 'friend' : ""
			dataRegister.affiliateCode = getParamsRef ? getParamsRef : refCode
			setAffiliateCode(getParamsRef ? getParamsRef : refCode)

			setKnowFrom(dataRegister.knowFrom)
			if (dataRegister.knowFrom) {
				form.setFieldsValue({ "knowFrom": dataRegister.knowFrom })
			}
		}
		return () => {
			setTokenTurnstile("")
		}
	}, [form]) //eslint-disable-line

	if (!prefix || (prefix?.functions.hasOwnProperty("offRegister") && prefixState?.info?.functions['offRegister'])) {
		return (
			<>
				<div style={{ textAlign: "center", marginTop: 20 }}><Text>ไม่สามารถสมัครสมาชิกได้ในขณะนี้</Text></div>
				{(prefix?.functions.hasOwnProperty("homePage") && prefix?.functions['homePage']) && (
					<Link to={makePath('/home')}>
						<div style={{ textAlign: 'center', padding: '8px 0', marginTop: 16 }}>
							<Text><i className="fa-solid fa-house" style={{ fontSize: '18px', marginRight: 5 }}></i> กลับหน้าหลัก</Text>
						</div>
					</Link>
				)}
			</>
		)
	}

	return (
		<>
			{false && <div className="register-main-slider"><MainSlider prefixState={prefixState} /></div>}
			<div className="form-card">
				<div className="form-card-body" style={{ paddingTop: 12, paddingBottom: 20 }}>
					<Tabs defaultActiveKey="register" className="tab-auth" items={itemTabs} onTabClick={handleTabs} />
					<Form
						form={form}
						name="registerForm"
						layout="vertical"
						style={{ marginTop: 0 }}
						hideRequiredMark>
						{stepOTP ?
							<RegisterStepVerifyOTP
								isSubmitting={isSubmitting}
								handleSubmit={handleSubmit}
								phone={dataRegister.phone}
								verifyRef={verifyRefOTP}
								setVerifyRefOTP={setVerifyRefOTP}
								setVerifyTransactionID={setVerifyTransactionID}
								setVerifyTokenOTP={setVerifyTokenOTP}
								setStep={setStep}
								setStepOTP={setStepOTP}
								setOTPForm={(e: any) => form.setFieldsValue({ "otp": e })}
								prefixInfo={prefix}
								setTokenTurnstileOTP={setTokenTurnstileOTP}
								tokenTurnstileOTP={tokenTurnstileOTP}
							/> :
							<>
								{step === 1 && <RegisterStep1 isSubmitting={isSubmitting} handleSubmit={handleSubmit} prefixInfo={prefix} setTokenTurnstileOTP={setTokenTurnstileOTP} resetCounter={resetCounter} />}
								{step === 2 && (
									<RegisterStep2 bankCode={bankCode} setBankCode={setBankCode} isSubmitting={isSubmitting} handleSubmit={handleSubmit} prefixInfo={prefix} />
								)}
								{step === 3 && (
									<RegisterStep3
										knowFrom={knowFrom}
										setKnowFrom={setKnowFrom}
										defaultAffiliateCode={affiliateCode}
										promotions={prefix.promotions}
										isSubmitting={isSubmitting}
										handleSubmit={handleSubmit}
										setTurnstile={setTurnstile}
										recaptcha={prefix.recaptcha}
										resetCounter={resetCounter}
										termandcondition={prefix?.termandcondition?.termandconditionDescription as string}
										termandconditionModal={termandconditionModal}
										setCheckTermandcondition={setCheckTermandcondition}
									/>
								)}
								{step === 4 && (
									<div style={{ marginBottom: 12, marginTop: 20 }}>
										<Title level={4} style={{ margin: '0 0 8px', textAlign: 'center' }}>สมัครสมาชิกสำเร็จแล้ว</Title>
										<div style={{ margin: '0 0 16px', textAlign: 'center' }}>คุณสามารถใช้เบอร์โทรศัพท์และรหัสผ่าน<br />ด้านล่างเพื่อเข้าสู่ระบบ</div>
										<Card className="white-card" type="inner" size="small" style={{ marginBottom: '16px' }}><span style={{ opacity: 0.85 }}>เบอร์โทรศัพท์:</span> {dataRegister.phone}</Card>
										<Card className="white-card" type="inner" size="small"><span style={{ opacity: 0.85 }}>รหัสผ่าน:</span> {dataRegister.password}</Card>
										<Link to={makePath((prefix?.game)?.toUpperCase() === "AMBKING" ? '/launch-game' : '/wallet')}>
											<Button
												type="primary"
												block
												style={{ marginTop: '16px' }}
											>
												เข้าสู่หน้าหลัก
											</Button>
										</Link>
									</div>
								)}
							</>
						}
					</Form>

					{step !== 4 && (
						// <div style={{ textAlign: 'center' }}>
						// 	<Text>ต้องการเข้าสู่ระบบใช่ไหม? <Link to={makePath('/login')} style={{ color: '#fff', textDecoration: 'underline' }}>เข้าสู่ระบบ</Link></Text>
						// </div>
						<>
							{!showMenuSupport && (
								<Link to={"#"} onClick={handleModalSupport}>
									<div style={{ textAlign: 'center', padding: '8px 0 4px' }}>
										<Text>หากมีข้อสงสัยติดต่อ <span style={{ color: '#fff', textDecoration: 'underline' }}>ฝ่ายบริการลูกค้า</span></Text>
									</div>
								</Link>
							)}
							{prefix?.lineLink && showMenuSupport && (
								<Link to={{ pathname: prefix.lineLink }} target="_blank">
									<div style={{ textAlign: 'center', padding: '8px 0 4px' }}>
										<Text>หากมีข้อสงสัยติดต่อ <span style={{ color: '#fff', textDecoration: 'underline' }}>ฝ่ายบริการลูกค้า</span></Text>
									</div>
								</Link>
							)}
						</>
					)}


					{/* {!showMenuSupport && (
						<Link to={"#"} onClick={handleModalSupport} className="chat-button">
							<Button className="ant-btn-dark" style={{ color: '' }}>
								<i className="fa-solid fa-comments"></i>
								<span>ติดต่อฝ่ายบริการลูกค้า</span>
							</Button>
						</Link>
					)}
					{prefix?.lineLink && showMenuSupport && (
						<Link to={{ pathname: prefix.lineLink }} target="_blank" className="chat-button">
							<Button className="ant-btn-dark">
								<i className="fa-brands fa-line" />
								<span>ติดต่อฝ่ายบริการลูกค้า</span>
							</Button>
						</Link>
					)} */}
				</div>
			</div>
			<ModalSupport isOpen={openSupport} onClose={() => setOpenSupport(false)} />
			<ModalTerms isOpen={openTermandconditionModal} onClose={() => setOpenTermandconditionModal(false)} description={prefixState.info?.termandcondition?.termandconditionDescription || ""} />

			{(prefixState?.info?.functions.hasOwnProperty("homePage") && prefixState?.info?.functions['homePage'] && step !== 4) && (
				<Link to={makePath('/home')}>
					<div style={{ textAlign: 'center', padding: '8px 0', marginTop: 16 }}>
						<Text><i className="fa-solid fa-house" style={{ fontSize: '18px', marginRight: 5 }}></i> กลับหน้าหลัก</Text>
					</div>
				</Link>
			)}
		</>
	)
}

export default Register
