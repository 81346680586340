import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import BankDepositItem from '../components/Deposit/BankDepositItem'
import { ApplicationState } from '../store'
import { Avatar, Button, Modal, Typography } from 'antd'
import { Banks, getBankColor, getBankName } from '../helpers/bank'
import { useAppContext } from '../contexts/AppContext'
import UploadSlip from '../components/Deposit/UploadSlip'

const { Text } = Typography

interface Props {
	isOpen: boolean
	onClose: () => void
}

const BankDeposit: FC<Props> = ({ isOpen, onClose }) => {
	const depositToList = useSelector((state: ApplicationState) => state.deposit.depositToList)
	const userState = useSelector((state: ApplicationState) => state.user)
	const prefix = useSelector((state: ApplicationState) => state.prefix.info)

	const [openUploadSlip, setOpenUploadSlip] = useState<boolean>(false);

	const { isModalSocket, setIsModalSocket } = useAppContext();

	useEffect(() => {
		if (isModalSocket === false) {
			onClose()
			setIsModalSocket(true)
		}
	}, [isModalSocket]) //eslint-disable-line

	const depositToBanks = depositToList.filter((bank) => bank.bank !== 'truewallet')
	if (depositToBanks.length <= 0) {
		return <Redirect to="/deposit" />
	}

	const bankMember = Banks.filter(bankName => bankName.key === userState.info?.bankCode.toUpperCase())[0]

	return (
		<>
			<Modal
				className="ant-modal-deposit"
				// open={isModalSocket && isOpen ? true : false}
				open={isOpen}
				title="โอนผ่านธนาคาร"
				centered
				onCancel={onClose}
				footer={null}
			>
				<div className="notice-card warning">
					<div className="notice-card-icon">
						<img src="/icons/new/notice.svg" alt="notice icon" />
					</div>
					{prefix?.prefix === '188S' ? (
						<Text className="notice-card-text">
							เมื่อทำรายการโอนเงินแล้ว <Text type="warning"><strong>กรุณาแนบสลิปทุกครั้ง เพื่อทำการเติมเงินเข้าระบบ</strong></Text>
						</Text>
					) : (
						<Text className="notice-card-text">
							ระบบจะเติมเงินอัตโนมัติเข้ายูสเซอร์ของท่าน <Text type="warning"><strong>ภายใน 30 วินาที</strong></Text>
						</Text>
					)}

				</div>
				{/* <div className="notice-card danger">
				<div className="notice-card-icon">
					<img src="/icons/new/danger.svg" alt="notice icon" />
				</div>
				สามารถโอนเงินเข้าธนาคารโดยใช้ <Text type="danger"><strong>ชื่อบัญชี/เลขบัญชี</strong></Text> ที่สมัครเข้ามาเท่านั้น
				<Text className="notice-card-text">
					<div style={{ display: 'flex' }}>
						<img
							src={bankMember.image}
							alt={getBankName(userState.info?.bankCode)}
							style={{ width: 28, marginRight: 8 }}
						/>
						<div>
							{userState.info?.bankNumber}<br />
							{userState.info?.firstName} {userState.info?.lastName}
						</div>
					</div>
					<span>กรุณาใช้บัญชีธนาคารนี้โอนเข้ามาเท่านั้น</span>
				</Text>
			</div> */}

				<div className="bank-deposit-card bank-form" style={{ marginBottom: 16 }}>
					<div
						className="bank-card-logo"
						style={{ boxShadow: `0 8px 12px -4px ${getBankColor(userState.info?.bankCode)}` }}
					>
						<img
							src={bankMember.image}
							alt={getBankName(userState.info?.bankCode)}
						/>
					</div>
					<div className="bank-card-info">
						<div><Text type="secondary"><small>{getBankName(userState.info?.bankCode)}</small></Text></div>
						<Text className="bank-deposite-account">{userState.info?.bankNumber}</Text>
						<div><Text type="secondary"><small>{userState.info?.firstName} {userState.info?.lastName}</small></Text></div>
					</div>
				</div>
				<div className='bank-deposit-arrow'>
					<div className='arrow arrow-first'></div>
					<div className='arrow arrow-second'></div>
				</div>
				{depositToBanks.map((bank) => (
					<BankDepositItem bank={bank} key={bank.accountNumber} />
				))}
				{(prefix?.functions.scanqr) && (
					<Button
						block
						onClick={() => setOpenUploadSlip(true)}
						style={{ borderRadius: 12, marginTop: 12 }}>อัปโหลดสลิป</Button>
				)}
			</Modal>
			<UploadSlip isOpen={openUploadSlip} onClose={() => setOpenUploadSlip(false)} />
		</>
	)
}

export default BankDeposit
